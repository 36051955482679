.main-footer{
    color: #FAC9B8;
    width: 100%;
    position: relative;
    bottom: 0;

}

.row-info{
    padding-bottom: 32px;
    
}

.footer-container{
    color: #FAC9B8;
    background-color: #22223B;
    width: 100%;
    flex-grow: 1;
    flex-direction: column-reverse;

}
.scroll-button{
    margin-right: 16px;
    text-align: right;
}

*:focus {
    outline: none !important;
}