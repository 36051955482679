.resize-img{
    height: 300px;
    width: auto;
    object-fit: cover;
}
.resize-collection{
    height: 600px;
    width: auto;
    object-fit: cover;
}
.resize-img-mobile{
    width: 200px;
    height: auto;
    object-fit: cover;
}
