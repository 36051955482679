.resize-img{
    height: 200px;
    width: auto;
}
.resize-img-mobile{
    height: 100px;
    width: auto;
}

.carousel-control-next,
.carousel-control-prev /*, .carousel-indicators */ {
    filter: invert(100%);
}