.container {
    background-color: #ffffff;
    border-radius: 25px;
    min-width: 75vw;
    max-width: 90vw;
}

body {
    background-color: #508991;
}

.map {
    min-height: 450px;
}
p {
    color: #22223B;
}

h2 {
    color: #22223B;
}

.course-link {
    color:#22223B;
}

.course-link:hover {
    color: black;
  }